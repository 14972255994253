/**
 *
 * App
 *
 * This component decides which sub-app to load.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { createLoader } from 'utils/createLoader';
import { isLedWall, isMobileApp, isMobileDevice } from 'utils/mode';

const LedWallLoader = createLoader(() =>
  import(/* webpackChunkName: "led-wall" */ './LedWall')
);
const MobileAppLoader = createLoader(() =>
  import(/* webpackChunkName: "mobile-app" */ './MobileApp')
);
const MobileWebLoader = createLoader(() =>
  import(/* webpackChunkName: "mobile-web" */ './MobileWeb')
);
const DesktopWebLoader = createLoader(() =>
  import(/* webpackChunkName: "desktop-web" */ './DesktopWeb')
);

function App({ location }) {
  const AppComp = isLedWall()
    ? LedWallLoader
    : isMobileApp()
    ? MobileAppLoader
    : isMobileDevice()
    ? MobileWebLoader
    : DesktopWebLoader;

  return (
    <>
      <Helmet
        titleTemplate="%s - Wind Creek Social Casino"
        defaultTitle="Wind Creek Social Casino"
      />

      <AppComp location={location} />
    </>
  );
}

export default App;
