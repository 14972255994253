import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import Countdown from 'react-countdown-now';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import A11yFontIcon from 'components/A11yFontIcon';
import {
  clearMaintenanceCookie,
  getCredentialsSetting,
  setMaintenanceCookie
} from 'utils/cookies';
import { fromIso9075DateString } from 'utils/dates';
import { padNumber } from 'utils/formatting';
import { getMaintenanceMode, setMaintenanceMode } from 'utils/localStorage';
import './MaintenanceMode.css';

const TIMER_LIMIT_MINUTES = 5;
const FETCH_OPTIONS = {
  credentials: getCredentialsSetting(),
  mode: 'cors',
  cache: 'no-cache'
};

const timerRenderer = ({ hours, minutes, seconds }) => {
  const minutesRemaining = minutes + hours * 60;
  return minutesRemaining > TIMER_LIMIT_MINUTES ? (
    <span>
      Estimated time until completion: {padNumber(hours)}:{padNumber(minutes)}:
      {padNumber(seconds)}
    </span>
  ) : null;
};

class MaintenanceMode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenanceMode: getMaintenanceMode(),
      accessFormOpen: false,
      accessFormCode: ''
    };

    this.querying = false;
    this.maintenanceModeUpdateSubscription = null;

    this.toggleAccessForm = this.toggleAccessForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNetworkError = this.handleNetworkError.bind(this);
    this.handleMaintenanceModeChange =
      this.handleMaintenanceModeChange.bind(this);
  }

  componentDidMount() {
    this.props.client.addNetworkErrorListener(this.handleNetworkError);
    this.queryMaintenanceMode();
    this.subscribeToMaintenanceModeUpdate();
  }

  componentWillUnmount() {
    this.props.client.removeNetworkErrorListener(this.handleNetworkError);
    if (this.maintenanceModeUpdateSubscription) {
      this.maintenanceModeUpdateSubscription.unsubscribe();
      this.maintenanceModeUpdateSubscription = null;
    }
  }

  handleNetworkError(networkError) {
    const { maintenanceMode } = this.state;
    const enable = maintenanceMode && maintenanceMode.enabled;
    if (networkError.statusCode === 412 && !enable) {
      // 412 PRECONDITION FAILED indicates maintenance mode is on
      this.queryMaintenanceMode();
    }
  }

  handleMaintenanceModeChange(json) {
    const curr = this.state.maintenanceMode;
    if (
      'enabled' in json &&
      (json.enabled !== curr.enabled ||
        json.message !== curr.message ||
        json.estimatedEnd !== curr.estimatedEnd)
    ) {
      const maintenanceMode = {
        enabled: json.enabled,
        message: json.message,
        estimatedEnd: json.estimatedEnd
      };
      setMaintenanceMode(maintenanceMode);
      this.setState({ maintenanceMode });
    }

    if (json.enabled) {
      // Cookie must be wrong, clear it to ease CloudFront cache growth
      clearMaintenanceCookie();
    }
  }

  toggleAccessForm(event) {
    const open = this.state.accessFormOpen;
    this.setState({ accessFormOpen: !open });
    if (open) {
      this.handleSubmit(event);
    }
  }

  handleChange(event) {
    this.setState({ accessFormCode: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const code = this.state.accessFormCode.trim();
    if (code) {
      setMaintenanceCookie(code);
      window.location.reload();
    }
  }

  render() {
    const { enabled, message, estimatedEnd } = this.state.maintenanceMode;
    if (!enabled) {
      return this.props.children;
    }

    const { accessFormOpen, accessFormCode } = this.state;

    return (
      <div className="maintenance-mode-container">
        <Helmet>
          <title>Maintenance Downtime - Wind Creek Social Casino</title>
        </Helmet>
        <div className="logo" />
        <div className="title">
          SITE IS CURRENTLY UNAVAILABLE DUE TO MAINTENANCE
        </div>
        <div className="text">
          <div>
            {message &&
              message
                .split('\n')
                .filter(line => line.trim())
                .map((line, index) => <div key={index}>{line}</div>)}
          </div>
          <div>
            {estimatedEnd && (
              <Countdown
                date={fromIso9075DateString(estimatedEnd)}
                renderer={timerRenderer}
              />
            )}
          </div>
          <div>Please check back later.</div>
        </div>
        <div className="access-container">
          {accessFormOpen && (
            <form onSubmit={this.handleSubmit}>
              <input
                className="access-form"
                type="password"
                value={accessFormCode}
                onChange={this.handleChange}
                autoFocus
              />
            </form>
          )}
          <button
            onClick={this.toggleAccessForm}
            type="button"
            className="btn toggle-button"
            aria-hidden="true"
          >
            <A11yFontIcon
              icon={accessFormOpen ? 'unlock' : 'lock'}
              fixed
              alt=""
            />
          </button>
        </div>
      </div>
    );
  }

  queryMaintenanceMode() {
    if (!this.querying) {
      // GraphQL endpoint is disabled during maintenance mode, so fetch directly
      this.querying = true;
      fetch(process.env.REACT_APP_API_URI + '/maintenance', FETCH_OPTIONS)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
          return Promise.reject();
        })
        .then(json => {
          this.handleMaintenanceModeChange(json);
        })
        .finally(() => {
          this.querying = false;
        });
    }
  }

  subscribeToMaintenanceModeUpdate() {
    if (this.maintenanceModeUpdateSubscription) {
      this.maintenanceModeUpdateSubscription.unsubscribe();
    }
    const that = this;
    this.maintenanceModeUpdateSubscription = this.props.client
      .subscribe({
        query: SUBSCRIPTION_MAINTENANCEMODE
      })
      .subscribe({
        next(subscriptionData) {
          that.handleMaintenanceModeChange(subscriptionData.data);
        },
        error(err) {
          console.error('Error in MaintenanceModeUpdate: ', err);
        }
      });
  }
}

const SUBSCRIPTION_MAINTENANCEMODE = gql`
  subscription maintenanceModeUpdate {
    maintenanceModeUpdate {
      enabled
      message
      estimatedEnd
    }
  }
`;

export default withApollo(withRouter(MaintenanceMode));
